/* eslint-disable react/display-name */
import React from 'react';

import { UserSwitchOutlined } from '@ant-design/icons';
import { MailOutlined } from '@ant-design/icons';
import { Button, message, Card, Row, Popconfirm } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';

import { relation } from 'components/Generic/genericHelper';
import Loader from 'components/Loader';
import UserDetail from 'components/User/UserDetail';

const userProxyAction = (id) => (
	<Button
		key="login"
		type="link"
		icon={<UserSwitchOutlined />}
		onClick={async () => {
			const axios = await createCoreAxiosInstance();
			const {
				data: { access, refresh },
			} = await axios.get(`api/v2/user/${id}/token`);
			window.open(
				`${process.env.REACT_APP_CLIENT_DASHBOARD_URL}set-tokens?access-token=${access}&refresh-token=${refresh}`,
			);
		}}
	>
		Login
	</Button>
);

const userOneDashProxyAction = (id) => (
	<Button
		key="login-one-dash"
		type="link"
		icon={<UserSwitchOutlined />}
		onClick={async () => {
			try {
				const axios = await createCoreAxiosInstance();
				const {
					data: { access, refresh },
				} = await axios.get(`api/v2/user/${id}/token`);
				window.open(
					`${process.env.REACT_APP_ONE_DASHBOARD_IMPERSONATION_URL}?accessToken=${access}&refreshToken=${refresh}`,
				);
			} catch (error) {
				if (error.response) {
					alert(`Error: ${error.response.data.message || 'An error occurred'}`);
				} else if (error.request) {
					alert('No response received from the server.');
				} else {
					alert(`Error: ${error.message}`);
				}
			}
		}}
	>
		Login One Dashboard
	</Button>
);

/* eslint-disable react/prop-types */
const ResendRegistrationToken = ({ userID }) => {
	const resendEmail = async () => {
		try {
			const axios = await createCoreAxiosInstance();
			await axios.head(`api/v2/register/resendToken/${userID}`);
			message.success('Registration Email Sent');
		} catch (err) {
			message.error('Error Sending Email - Check Elk Logs');
		}
	};

	return (
		<Card>
			<Row justify="center">
				<Popconfirm
					title="An email will be sent to the address stored in Stripe! Are you sure?"
					onConfirm={resendEmail}
					okText="Send Email"
					cancelText="Cancel"
				>
					<Button icon={<MailOutlined />}>
						{' '}
						Resend Registration Email (Stripe)
					</Button>
				</Popconfirm>
			</Row>
		</Card>
	);
};

const routes = [
	{
		path: '/list/users',
		layout: 'list',
		sideMenu: true,
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
                query AllUsers($limit: Int!, $offset: Int!, $search: String) {
                    users(limit: $limit, offset: $offset, search: $search) {
                        id,
                        username,
                        firstName,
                        lastName,
                        email,
                        isActive,
                        companies {
                            id,
                            name
                        }
                        parentCompany {
                            id,
                            name
                        }
                    }
                }
		        `,
			relations: [
				relation('company', 'many'),
				relation('parentCompany', 'single'),
			],
			customActions: [userProxyAction, userOneDashProxyAction],
			...relation('user', 'many'),
			excludeColumns: ['firstName', 'lastName'],
		},
	},
	{
		path: '/list/users/:id',
		layout: 'list',
		sideMenu: false,
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
		            query User($id: Int!) {
		                user(id: $id) {
		                    id
		                    username
		                    email
		                    companies {
		                        id,
		                        name
		                    }
		                    parentCompany {
		                        id,
		                        name
		                    }
		                    firstName
		                    lastName
                            isActive
		                }
		             }
		        `,
			relations: [
				relation('company', 'many'),
				relation('parentCompany', 'single'),
			],
			customActions: [userProxyAction, userOneDashProxyAction],
			...relation('user', 'single'),
			extra: (user) => {
				return (
					<>
						<ResendRegistrationToken userID={user.id} />
						<UserDetail {...user} />
					</>
				);
			},
		},
	},
];

export default routes;
