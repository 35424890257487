/* eslint-disable react/display-name */
import React from 'react';

import { DeleteOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message, Typography, Divider } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';

import MappingDetail from 'components/Company/MappingDetail';
import StorefrontDetail from 'components/Company/Storefront/StorefrontDetail';
import { relation } from 'components/Generic/genericHelper';
import GenericList from 'components/Generic/GenericList';
import Loader from 'components/Loader';

const companyDeactivateAction = (id, entity, performQuery) => (
	<Popconfirm
		title="Deactivating cannot be undone!! this will deactivate the company/user and delete all integrations"
		onConfirm={async () => {
			try {
				const axios = await createCoreAxiosInstance();
				await axios.delete(`api/v2/company/${id}`);
				message.success('Company Deactivated');
			} catch (err) {
				message.error('Error Deactivating Company');
			} finally {
				performQuery();
			}
		}}
		okText="Deactivate"
		cancelText="Cancel"
	>
		<Button key="deactivate" icon={<DeleteOutlined />}>
			Deactivate Company
		</Button>
	</Popconfirm>
);

const togglePosActive = (id, entity, performQuery, item) => {
	return (
		<Popconfirm
			title={`Toggle POS Active to ${!item.posActive}?`}
			onConfirm={async () => {
				try {
					const axios = await createCoreAxiosInstance();
					await axios.put(`api/v2/company/${id}`, {
						pos_active: !item.posActive,
					});
					message.success('Success!');
				} catch (err) {
					message.error('Error Toggling POS Active');
				} finally {
					performQuery();
				}
			}}
			okText="Toggle"
			cancelText="Cancel"
		>
			<Button key="posActive">POS Active</Button>
		</Popconfirm>
	);
};

const toggleAutoAccept = (id, entity, performQuery, item) => {
	return (
		<Popconfirm
			title={`Toggle Auto Accept  to ${!item.autoAccept}?`}
			onConfirm={async () => {
				try {
					const axios = await createCoreAxiosInstance();
					await axios.put(`api/v2/company/${id}`, {
						auto_accept: !item.autoAccept,
					});
					message.success('Success!');
				} catch (err) {
					message.error('Error Toggling Auto Accept');
				} finally {
					performQuery();
				}
			}}
			okText="Toggle"
			cancelText="Cancel"
		>
			<Button key="autoAccept">Auto Accept</Button>
		</Popconfirm>
	);
};

const toggleScheduledAutoAccept = (id, entity, performQuery, item) => {
	return (
		<Popconfirm
			title={`Toggle Scheduled Auto Accept to ${!item.scheduledOrderAutoAccept}?`}
			onConfirm={async () => {
				try {
					const axios = await createCoreAxiosInstance();
					await axios.put(`api/v2/company/${id}`, {
						scheduled_order_auto_accept: !item.scheduledOrderAutoAccept,
					});
					message.success('Success!');
				} catch (err) {
					message.error('Error Toggling Auto Accept');
				} finally {
					performQuery();
				}
			}}
			okText="Toggle"
			cancelText="Cancel"
		>
			<Button key="scheduledOrderAutoAccept">Scheduled Auto Accept</Button>
		</Popconfirm>
	);
};

// eslint-disable-next-line no-unused-vars
const companyResendRegistrationAction = (id) => {
	const resendEmail = async () => {
		try {
			const axios = await createCoreAxiosInstance();
			await axios.head(`api/v2/register/resendToken/${id}`);
			message.success('Registration Email Sent');
		} catch (err) {
			message.error('Error Sending Email - Check Logs');
		}
	};

	return (
		<Popconfirm
			title="An email will be sent to the address stored in email2 field! Are you sure?"
			onConfirm={resendEmail}
			okText="Send Email"
			cancelText="Cancel"
		>
			<Button icon={<MailOutlined />}>Resend Registration Email</Button>
		</Popconfirm>
	);
};

const routes = [
	{
		path: '/list/companies',
		layout: 'list',
		sideMenu: true,
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
                query AllCompanies($limit: Int!, $offset: Int!, $search: String) {
                    companies(limit: $limit, offset: $offset, search: $search) {
                        id,
                        name,
                        pickupTime,
                        deliveryTime,
                        autoAccept,
                        scheduledOrderAutoAccept,
												email,
												email2,
												active,
                        billingStatus
                        users {
                            id,
                            username
                        }
                        merchants {
                            id,
                            name
                        }
                        parentCompany {
                            id
                            name
                        }
                        stripeSubscriptionItemId
                        stripeCustomerId
                    }
                }
            `,
			relations: [
				relation('user', 'many'),
				relation('parentCompany', 'single'),
				relation('merchant', 'many'),
			],
			...relation('company', 'many'),
			excludeColumns: [
				'pickupTime',
				'deliveryTime',
				'autoAccept',
				'scheduledOrderAutoAccept',
				'email',
				'stripeSubscriptionItemId',
				'stripeCustomerId',
			],
		},
	},
	{
		path: '/list/companies/:id',
		layout: 'list',
		sideMenu: false,
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
	            query Company($id: Int!) {
	                company(id: $id) {
	                    id,
	                    name,
						address,
						address2,
						active,
	                    updated,
	                    pickupTime,
	                    deliveryTime,
	                    companyMeta,
	                    timezone,
	                    posActive,
	                    merchantUuid,
	                    updated,
						autoAccept,
						scheduledOrderAutoAccept,
	                    stripeCustomerId,
						chargify {
							subscriptionId
						}
	                    stripeSubscriptionItemId,
	                    created,
	                    email,
						email2,
	                    city,
	                    state,
	                    zipCode,
						country,
						storefrontDeliveryActive,
						storefrontSelfDeliveryActive,
						storefrontSelfDeliveryFee,
						storefrontSelfDeliveryMaximumDistanceKilometers,
						enableRushHour,
						users {
	                        id,
	                        username
	                    }
	                    parentCompany {
	                        id
	                        name
	                    }
	                    merchants {
	                        id
	                        name
                        }
                        doordashSet {
                            id
                            displayName
                        }
	                }
	             }
	        `,
			relations: [
				relation('user', 'many'),
				relation('parentCompany', 'single'),
				relation('chargify', 'single'),
				relation('merchant', 'many'),
			],
			customActions: [
				companyDeactivateAction,
				togglePosActive,
				toggleAutoAccept,
				toggleScheduledAutoAccept,
			],
			...relation('company', 'single'),
			// eslint-disable-next-line react/prop-types
			extra: (detail) => (
				<>
					<MappingDetail
						companyID={parseInt(detail?.id)}
						companyName={detail?.name}
						companyEmail={detail?.email}
						companyEmail2={detail?.email2}
						companyData={detail}
					/>
					<Divider />
					<StorefrontDetail
						companyID={detail?.id}
						storefrontDeliveryActive={detail?.storefrontDeliveryActive}
						storefrontSelfDeliveryActive={detail?.storefrontSelfDeliveryActive}
						storefrontSelfDeliveryFee={detail?.storefrontSelfDeliveryFee}
						storefrontSelfDeliveryMaximumDistanceKilometers={
							detail?.storefrontSelfDeliveryMaximumDistanceKilometers
						}
					/>
					<Divider />
					<Typography.Title>{`${detail.name}'s Orders`}</Typography.Title>
					<GenericList
						showListTitle={false}
						{...relation('order', 'many')}
						relations={[relation('merchant', 'single')]}
						getDataSourceFromQuery={(data) => data?.company.orders}
						excludeColumns={[
							'created',
							'appId',
							'deliveryAddress',
							'pickup',
							'fulfillmentTime',
							'tableElements',
							'specialInstructs',
							'nameClient',
							'phoneClient',
							'subtotal',
							'tax',
							'discount',
							'tip',
							'grandTotal',
							'posSynced',
						]}
						query={`
                            query CompanyOrders($limit: Int!, $offset: Int!, $search: String) {
                                company(id: ${detail.id}) {
                                    orders(limit: $limit, offset: $offset, search: $search) {
                                        id
                                        code
                                        deliveryAddress
                                        pickup
                                        fulfillmentType
                                        fulfillmentTime
                                        tableElements
                                        specialInstructs
                                        nameClient
                                        phoneClient
                                        subtotal
                                        tax
                                        discount
                                        tip
                                        grandTotal
                                        created
                                        app
                                        posSynced
                                        appId
                                        statusOrder
                                        merchant {
                                            id
                                            name
                                        }
                                    }
                                }
                            }
                    `}
					/>
				</>
			),
		},
	},
];

export default routes;
